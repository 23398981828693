import { Confirmation } from '@/enums/common.enum';
import type { CommonCountry } from '@/types/common/common.type';
import type { FormOption, FormOptionGroup } from '@/types/common/form.type';

export const REGION_LIST = {
  NORTH_AMERICA: 'north_america', // 1
  CENTRAL_AMERICA: 'central_america', // 2
  SOUTH_AMERICA: 'south_america', // 3
  WESTERN_EUROPE: 'western_europe', // 4
  EASTERN_EUROPE: 'eastern_europe', // 5
  NORTHERN_EUROPE: 'northern_europe',
  EAST_ASIA: 'east_asia',
  SOUTHEAST_ASIA: 'southeast_asia',
  SOUTHERN_ASIA: 'southern_asia',
  CENTRAL_ASIA: 'central_asia',
  WESTERN_ASIA: 'western_asia',
  OCEANIA: 'oceania',
  NORTH_AFRICA: 'north_africa',
  WESTERN_AFRICA: 'western_africa',
  CENTRAL_AFRICA: 'central_africa',
  EAST_AFRICA: 'east_africa',
  SOUTHERN_AFRICA: 'southern_africa',
  OTHER: 'other'
};

export const COUNTRY_LIST: CommonCountry[] = [
  {
    code: 'AF',
    krName: '아프가니스탄',
    name: 'Afghanistan',
    phoneCd: '+93',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_ASIA
  },
  {
    code: 'AQ',
    krName: '남극',
    name: 'Antarctic',
    phoneCd: '+672',
    euMember: Confirmation.NO,
    region: REGION_LIST.OTHER
  },
  {
    code: 'BV',
    krName: '부베 섬',
    name: 'Bouvet Island',
    phoneCd: '+47',
    euMember: Confirmation.NO,
    region: REGION_LIST.OTHER
  },
  {
    code: 'AL',
    krName: '알바니아',
    name: 'Albania',
    phoneCd: '+355',
    euMember: Confirmation.NO,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'DZ',
    krName: '알제리',
    name: 'Algeria',
    phoneCd: '+213',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTH_AFRICA
  },
  {
    code: 'AS',
    krName: '아메리칸 사모아',
    name: 'American Samoa',
    phoneCd: '+1-684',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'AD',
    krName: '안도라',
    name: 'Andorra',
    phoneCd: '+376',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'AO',
    krName: '앙골라',
    name: 'Angola',
    phoneCd: '+244',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AFRICA
  },
  {
    code: 'AG',
    krName: '앤티가 바부다',
    name: 'Antigua and Barbuda',
    phoneCd: '+1-268',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'AR',
    krName: '아르헨티나',
    name: 'Argentina',
    phoneCd: '+54',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'AM',
    krName: '아르메니아',
    name: 'Armenia',
    phoneCd: '+374',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'AW',
    krName: '아루바',
    name: 'Aruba',
    phoneCd: '+297',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'AU',
    krName: '호주',
    name: 'Australia',
    phoneCd: '+61',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'CX',
    krName: '크리스마스 섬',
    name: 'Christmas Island',
    phoneCd: '+61',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'CC',
    krName: '코코스 제도',
    name: 'Cocos Islands',
    phoneCd: '+61',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'AT',
    krName: '오스트리아',
    name: 'Austria',
    phoneCd: '+43',
    euMember: Confirmation.YES,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'AZ',
    krName: '아제르바이잔',
    name: 'Azerbaijan',
    phoneCd: '+994',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'BS',
    krName: '바하마',
    name: 'Bahamas',
    phoneCd: '+1-242',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'BH',
    krName: '바레인',
    name: 'Bahrain',
    phoneCd: '+973',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'BD',
    krName: '방글라데시',
    name: 'Bangladesh',
    phoneCd: '+880',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_ASIA
  },
  {
    code: 'BB',
    krName: '바베이도스',
    name: 'Barbados',
    phoneCd: '+1-246',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'BY',
    krName: '벨라루스',
    name: 'Belarus',
    phoneCd: '+375',
    euMember: Confirmation.NO,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'BE',
    krName: '벨기에',
    name: 'Belgium',
    phoneCd: '+32',
    euMember: Confirmation.YES,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'BZ',
    krName: '벨리즈',
    name: 'Belize',
    phoneCd: '+501',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'BJ',
    krName: '베냉',
    name: 'Benin',
    phoneCd: '+229',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'BM',
    krName: '버뮤다',
    name: 'Bermuda',
    phoneCd: '+1-441',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTH_AMERICA
  },
  {
    code: 'PM',
    krName: '생피에르 미클롱',
    name: 'Saint Pierre and Miquelon',
    phoneCd: '+508',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTH_AMERICA
  },
  {
    code: 'BT',
    krName: '부탄',
    name: 'Bhutan',
    phoneCd: '+975',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_ASIA
  },
  {
    code: 'IO',
    krName: '영국령 인도양 지역',
    name: 'British Indian Ocean Territory',
    phoneCd: '+246',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_ASIA
  },
  {
    code: 'BO',
    krName: '볼리비아',
    name: 'Bolivia',
    phoneCd: '+591',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'BA',
    krName: '보스니아 헤르체고비나',
    name: 'Bosnia and Herzegovina',
    phoneCd: '+387',
    euMember: Confirmation.NO,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'BW',
    krName: '보츠와나',
    name: 'Botswana',
    phoneCd: '+267',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_AFRICA
  },
  {
    code: 'BR',
    krName: '브라질',
    name: 'Brazil',
    phoneCd: '+55',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'BN',
    krName: '브루나이',
    name: 'Brunei',
    phoneCd: '+673',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHEAST_ASIA
  },
  {
    code: 'BG',
    krName: '불가리아',
    name: 'Bulgaria',
    phoneCd: '+359',
    euMember: Confirmation.YES,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'BF',
    krName: '부르키나파소',
    name: 'Burkina Faso',
    phoneCd: '+226',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'BI',
    krName: '부룬디',
    name: 'Burundi',
    phoneCd: '+257',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'CV',
    krName: '카보베르데',
    name: 'Cabo Verde',
    phoneCd: '+238',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'KH',
    krName: '캄보디아',
    name: 'Cambodia',
    phoneCd: '+855',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHEAST_ASIA
  },
  {
    code: 'CM',
    krName: '카메룬',
    name: 'Cameroon',
    phoneCd: '+237',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AFRICA
  },
  {
    code: 'CA',
    krName: '캐나다',
    name: 'Canada',
    phoneCd: '+1',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTH_AMERICA
  },
  {
    code: 'KY',
    krName: '케이맨 제도',
    name: 'Cayman Islands',
    phoneCd: '+1-345',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'CF',
    krName: '중앙아프리카 공화국',
    name: 'Central African Republic',
    phoneCd: '+236',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AFRICA
  },
  {
    code: 'TD',
    krName: '차드',
    name: 'Chad',
    phoneCd: '+235',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AFRICA
  },
  {
    code: 'CL',
    krName: '칠레',
    name: 'Chile',
    phoneCd: '+56',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'CN',
    krName: '중국',
    name: 'China',
    phoneCd: '+86',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_ASIA
  },
  {
    code: 'HK',
    krName: '홍콩',
    name: 'Hong Kong',
    phoneCd: '+852',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_ASIA
  },
  {
    code: 'CO',
    krName: '콜롬비아',
    name: 'Colombia',
    phoneCd: '+57',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'KM',
    krName: '코모로',
    name: 'Comoros',
    phoneCd: '+269',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'CG',
    krName: '콩고 공화국',
    name: 'Congo, Republic of the',
    phoneCd: '+242',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AFRICA
  },
  {
    code: 'CD',
    krName: '콩고 민주 공화국',
    name: 'Congo, Democratic Republic of the',
    phoneCd: '+243',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AFRICA
  },
  {
    code: 'CK',
    krName: '쿡 제도',
    name: 'Cook Islands',
    phoneCd: '+682',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'CR',
    krName: '코스타리카',
    name: 'Costa Rica',
    phoneCd: '+506',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'CI',
    krName: '코트디부아르',
    name: "Côte d'Ivoire",
    phoneCd: '+225',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'HR',
    krName: '크로아티아',
    name: 'Croatia',
    phoneCd: '+385',
    euMember: Confirmation.YES,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'CU',
    krName: '쿠바',
    name: 'Cuba',
    phoneCd: '+53',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'CW',
    krName: '큐라소',
    name: 'Curaçao',
    phoneCd: '+599',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'CY',
    krName: '키프로스',
    name: 'Cyprus',
    phoneCd: '+357',
    euMember: Confirmation.YES,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'CZ',
    krName: '체코',
    name: 'Czech Republic',
    phoneCd: '+420',
    euMember: Confirmation.YES,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'DK',
    krName: '덴마크',
    name: 'Denmark',
    phoneCd: '+45',
    euMember: Confirmation.YES,
    region: REGION_LIST.NORTHERN_EUROPE
  },
  {
    code: 'DJ',
    krName: '지부티',
    name: 'Djibouti',
    phoneCd: '+253',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'DM',
    krName: '도미니카',
    name: 'Dominica',
    phoneCd: '+1-767',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'DO',
    krName: '도미니카 공화국',
    name: 'Dominican Republic',
    phoneCd: '+1-809',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'GP',
    krName: '과들루프',
    name: 'Guadeloupe',
    phoneCd: '+590',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'EC',
    krName: '에콰도르',
    name: 'Ecuador',
    phoneCd: '+593',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'FK',
    krName: '포클랜드 제도',
    name: 'Falkland Islands',
    phoneCd: '+500',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'GF',
    krName: '프랑스령 기아나',
    name: 'French Guiana',
    phoneCd: '+594',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'EG',
    krName: '이집트',
    name: 'Egypt',
    phoneCd: '+20',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTH_AFRICA
  },
  {
    code: 'SV',
    krName: '엘살바도르',
    name: 'El Salvador',
    phoneCd: '+503',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'GQ',
    krName: '적도 기니',
    name: 'Equatorial Guinea',
    phoneCd: '+240',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AFRICA
  },
  {
    code: 'ER',
    krName: '에리트레아',
    name: 'Eritrea',
    phoneCd: '+291',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'EE',
    krName: '에스토니아',
    name: 'Estonia',
    phoneCd: '+372',
    euMember: Confirmation.YES,
    region: REGION_LIST.NORTHERN_EUROPE
  },
  {
    code: 'FO',
    krName: '페로 제도',
    name: 'Faroe Islands',
    phoneCd: '+298',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTHERN_EUROPE
  },
  {
    code: 'SZ',
    krName: '에스와티니',
    name: 'Eswatini',
    phoneCd: '+268',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_AFRICA
  },
  {
    code: 'ET',
    krName: '에티오피아',
    name: 'Ethiopia',
    phoneCd: '+251',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'FJ',
    krName: '피지',
    name: 'Fiji',
    phoneCd: '+679',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'PF',
    krName: '프랑스령 폴리네시아',
    name: 'French Polynesia',
    phoneCd: '+689',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'TF',
    krName: '프랑스령 남방 및 남극 지역',
    name: 'French Southern and Antarctic Lands',
    phoneCd: '+262',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'FI',
    krName: '핀란드',
    name: 'Finland',
    phoneCd: '+358',
    euMember: Confirmation.YES,
    region: REGION_LIST.NORTHERN_EUROPE
  },
  {
    code: 'FR',
    krName: '프랑스',
    name: 'France',
    phoneCd: '+33',
    euMember: Confirmation.YES,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'GA',
    krName: '가봉',
    name: 'Gabon',
    phoneCd: '+241',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AFRICA
  },
  {
    code: 'GM',
    krName: '감비아',
    name: 'Gambia',
    phoneCd: '+220',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'GE',
    krName: '조지아',
    name: 'Georgia',
    phoneCd: '+995',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'DE',
    krName: '독일',
    name: 'Germany',
    phoneCd: '+49',
    euMember: Confirmation.YES,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'GI',
    krName: '지브롤터',
    name: 'Gibraltar',
    phoneCd: '+350',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'GG',
    krName: '건지',
    name: 'Guernsey',
    phoneCd: '+44-1481',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'GH',
    krName: '가나',
    name: 'Ghana',
    phoneCd: '+233',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'GR',
    krName: '그리스',
    name: 'Greece',
    phoneCd: '+30',
    euMember: Confirmation.YES,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'GL',
    krName: '그린란드',
    name: 'Greenland',
    phoneCd: '+299',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTH_AMERICA
  },
  {
    code: 'GD',
    krName: '그레나다',
    name: 'Grenada',
    phoneCd: '+1-473',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'GU',
    krName: '괌',
    name: 'Guam',
    phoneCd: '+1-671',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'HM',
    krName: '허드 섬과 맥도날드 섬',
    name: 'Heard Island and McDonald Islands',
    phoneCd: '+672',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'GT',
    krName: '과테말라',
    name: 'Guatemala',
    phoneCd: '+502',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'GN',
    krName: '기니',
    name: 'Guinea',
    phoneCd: '+224',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'GW',
    krName: '기니비사우',
    name: 'Guinea-Bissau',
    phoneCd: '+245',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'GY',
    krName: '가이아나',
    name: 'Guyana',
    phoneCd: '+592',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'HT',
    krName: '아이티',
    name: 'Haiti',
    phoneCd: '+509',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'HN',
    krName: '온두라스',
    name: 'Honduras',
    phoneCd: '+504',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'HU',
    krName: '헝가리',
    name: 'Hungary',
    phoneCd: '+36',
    euMember: Confirmation.YES,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'IS',
    krName: '아이슬란드',
    name: 'Iceland',
    phoneCd: '+354',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTHERN_EUROPE
  },
  {
    code: 'IN',
    krName: '인도',
    name: 'India',
    phoneCd: '+91',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_ASIA
  },
  {
    code: 'ID',
    krName: '인도네시아',
    name: 'Indonesia',
    phoneCd: '+62',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHEAST_ASIA
  },
  {
    code: 'IR',
    krName: '이란',
    name: 'Iran, Islamic Republic of',
    phoneCd: '+98',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'IQ',
    krName: '이라크',
    name: 'Iraq',
    phoneCd: '+964',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'IE',
    krName: '아일랜드',
    name: 'Ireland',
    phoneCd: '+353',
    euMember: Confirmation.YES,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'IM',
    krName: '맨 섬',
    name: 'Isle of Man',
    phoneCd: '+44',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'IL',
    krName: '이스라엘',
    name: 'Israel',
    phoneCd: '+972',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'IT',
    krName: '이탈리아',
    name: 'Italy',
    phoneCd: '+39',
    euMember: Confirmation.YES,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'JE',
    krName: '저지',
    name: 'Jersey',
    phoneCd: '+44',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'JM',
    krName: '자메이카',
    name: 'Jamaica',
    phoneCd: '+1-876',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'JP',
    krName: '일본',
    name: 'Japan',
    phoneCd: '+81',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_ASIA
  },
  {
    code: 'MO',
    krName: '마카오',
    name: 'Macau',
    phoneCd: '+853',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_ASIA
  },
  {
    code: 'JO',
    krName: '요르단',
    name: 'Jordan',
    phoneCd: '+962',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'KZ',
    krName: '카자흐스탄',
    name: 'Kazakhstan',
    phoneCd: '+7',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_ASIA
  },
  {
    code: 'KE',
    krName: '케냐',
    name: 'Kenya',
    phoneCd: '+254',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'KI',
    krName: '키리바시',
    name: 'Kiribati',
    phoneCd: '+686',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'KP',
    krName: '북한',
    name: "Korea, Democratic People's Republic of",
    phoneCd: '+850',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_ASIA
  },
  {
    code: 'KR',
    krName: '한국',
    name: 'Korea',
    phoneCd: '+82',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_ASIA
  },
  {
    code: 'KW',
    krName: '쿠웨이트',
    name: 'Kuwait',
    phoneCd: '+965',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'KG',
    krName: '키르기스스탄',
    name: 'Kyrgyzstan',
    phoneCd: '+996',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_ASIA
  },
  {
    code: 'LA',
    krName: '라오스',
    name: "Lao People's Democratic Republic",
    phoneCd: '+856',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHEAST_ASIA
  },
  {
    code: 'LV',
    krName: '라트비아',
    name: 'Latvia',
    phoneCd: '+371',
    euMember: Confirmation.YES,
    region: REGION_LIST.NORTHERN_EUROPE
  },
  {
    code: 'LB',
    krName: '레바논',
    name: 'Lebanon',
    phoneCd: '+961',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'LS',
    krName: '레소토',
    name: 'Lesotho',
    phoneCd: '+266',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_AFRICA
  },
  {
    code: 'LR',
    krName: '라이베리아',
    name: 'Liberia',
    phoneCd: '+231',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'LY',
    krName: '리비아',
    name: 'Libya',
    phoneCd: '+218',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTH_AFRICA
  },
  {
    code: 'LI',
    krName: '리히텐슈타인',
    name: 'Liechtenstein',
    phoneCd: '+423',
    euMember: Confirmation.NO,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'MK',
    krName: '마케도니아',
    name: 'Macedonia',
    phoneCd: '+389',
    euMember: Confirmation.NO,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'LT',
    krName: '리투아니아',
    name: 'Lithuania',
    phoneCd: '+370',
    euMember: Confirmation.YES,
    region: REGION_LIST.NORTHERN_EUROPE
  },
  {
    code: 'LU',
    krName: '룩셈부르크',
    name: 'Luxembourg',
    phoneCd: '+352',
    euMember: Confirmation.YES,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'MG',
    krName: '마다가스카르',
    name: 'Madagascar',
    phoneCd: '+261',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'MW',
    krName: '말라위',
    name: 'Malawi',
    phoneCd: '+265',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'MY',
    krName: '말레이시아',
    name: 'Malaysia',
    phoneCd: '+60',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHEAST_ASIA
  },
  {
    code: 'MV',
    krName: '몰디브',
    name: 'Maldives',
    phoneCd: '+960',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_ASIA
  },
  {
    code: 'ML',
    krName: '말리',
    name: 'Mali',
    phoneCd: '+223',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'MT',
    krName: '몰타',
    name: 'Malta',
    phoneCd: '+356',
    euMember: Confirmation.YES,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'MH',
    krName: '마셜 제도',
    name: 'Marshall Islands',
    phoneCd: '+692',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'MQ',
    krName: '마르티니크',
    name: 'Martinique',
    phoneCd: '+596',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'AN',
    krName: '네덜란드령 안틸레스',
    name: 'Netherlands Antilles',
    phoneCd: '+599',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'MS',
    krName: '몬세라트',
    name: 'Montserrat',
    phoneCd: '+1-664',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'MR',
    krName: '모리타니',
    name: 'Mauritania',
    phoneCd: '+222',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'MU',
    krName: '모리셔스',
    name: 'Mauritius',
    phoneCd: '+230',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'YT',
    krName: '마요트',
    name: 'Mayotte',
    phoneCd: '+262',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'MX',
    krName: '멕시코',
    name: 'Mexico',
    phoneCd: '+52',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTH_AMERICA
  },
  {
    code: 'FM',
    krName: '미크로네시아',
    name: 'Micronesia, Federated States of',
    phoneCd: '+691',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'MD',
    krName: '몰도바',
    name: 'Moldova, Republic of',
    phoneCd: '+373',
    euMember: Confirmation.NO,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'MC',
    krName: '모나코',
    name: 'Monaco',
    phoneCd: '+377',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'MN',
    krName: '몽골',
    name: 'Mongolia',
    phoneCd: '+976',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_ASIA
  },
  {
    code: 'ME',
    krName: '몬테네그로',
    name: 'Montenegro',
    phoneCd: '+382',
    euMember: Confirmation.NO,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'MA',
    krName: '모로코',
    name: 'Morocco',
    phoneCd: '+212',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTH_AFRICA
  },
  {
    code: 'MZ',
    krName: '모잠비크',
    name: 'Mozambique',
    phoneCd: '+258',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'RE',
    krName: '재결합',
    name: 'Réunion',
    phoneCd: '+262',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'MM',
    krName: '미얀마',
    name: 'Myanmar',
    phoneCd: '+95',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHEAST_ASIA
  },
  {
    code: 'NA',
    krName: '나미비아',
    name: 'Namibia',
    phoneCd: '+264',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_AFRICA
  },
  {
    code: 'NR',
    krName: '나우루',
    name: 'Nauru',
    phoneCd: '+674',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'NC',
    krName: '뉴 칼레도니아',
    name: 'New Caledonia',
    phoneCd: '+687',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'NP',
    krName: '네팔',
    name: 'Nepal',
    phoneCd: '+977',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_ASIA
  },
  {
    code: 'NL',
    krName: '네덜란드',
    name: 'Netherlands',
    phoneCd: '+31',
    euMember: Confirmation.YES,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'NZ',
    krName: '뉴질랜드',
    name: 'New Zealand',
    phoneCd: '+64',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'NI',
    krName: '니카라과',
    name: 'Nicaragua',
    phoneCd: '+505',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'NE',
    krName: '니제르',
    name: 'Niger',
    phoneCd: '+227',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'SH',
    krName: '세인트 헬레나',
    name: 'Saint Helena',
    phoneCd: '+290',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'NG',
    krName: '나이지리아',
    name: 'Nigeria',
    phoneCd: '+234',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'NU',
    krName: '니우에',
    name: 'Niue',
    phoneCd: '+683',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'NF',
    krName: '노퍽 섬',
    name: 'Norfolk Island',
    phoneCd: '+672',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'MP',
    krName: '북마리아나 제도',
    name: 'Northern Mariana Islands',
    phoneCd: '+1-670',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'NO',
    krName: '노르웨이',
    name: 'Norway',
    phoneCd: '+47',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTHERN_EUROPE
  },
  {
    code: 'SJ',
    krName: '스발바르 얀마옌 제도',
    name: 'Svalbard and Jan Mayen',
    phoneCd: '+47',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTHERN_EUROPE
  },
  {
    code: 'OM',
    krName: '오만',
    name: 'Oman',
    phoneCd: '+968',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'PS',
    krName: '팔레스타인',
    name: 'Palestine',
    phoneCd: '+970',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'PK',
    krName: '파키스탄',
    name: 'Pakistan',
    phoneCd: '+92',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_ASIA
  },
  {
    code: 'PW',
    krName: '팔라우',
    name: 'Palau',
    phoneCd: '+680',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'PA',
    krName: '파나마',
    name: 'Panama',
    phoneCd: '+507',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'PR',
    krName: '푸에르토리코',
    name: 'Puerto Rico',
    phoneCd: '+1',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'BL',
    krName: '생 바르텔레미',
    name: 'Saint Barthélemy',
    phoneCd: '+590',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'KN',
    krName: '세인트키츠네비스',
    name: 'Saint Kitts and Nevis',
    phoneCd: '+1-869',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'LC',
    krName: '세인트 루시아',
    name: 'Saint Lucia',
    phoneCd: '+1-758',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'MF',
    krName: '생 마르탱',
    name: 'Saint Martin',
    phoneCd: '+590',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'VC',
    krName: '세인트 빈센트 그레나딘',
    name: 'Saint Vincent and the Grenadines',
    phoneCd: '+1-784',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'SX',
    krName: '신트마르틴',
    name: 'Sint Maarten',
    phoneCd: '+1-721',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'PG',
    krName: '파푸아뉴기니',
    name: 'Papua New Guinea',
    phoneCd: '+675',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'PN',
    krName: '피트케언 제도',
    name: 'Pitcairn Islands',
    phoneCd: '+64',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'PY',
    krName: '파라과이',
    name: 'Paraguay',
    phoneCd: '+595',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'GS',
    krName: '사우스조지아 및 사우스샌드위치 제도',
    name: 'South Georgia and the South Sandwich Islands',
    phoneCd: '+500',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'PE',
    krName: '페루',
    name: 'Peru',
    phoneCd: '+51',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'PH',
    krName: '필리핀',
    name: 'Philippines',
    phoneCd: '+63',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHEAST_ASIA
  },
  {
    code: 'PL',
    krName: '폴란드',
    name: 'Poland',
    phoneCd: '+48',
    euMember: Confirmation.YES,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'PT',
    krName: '포르투갈',
    name: 'Portugal',
    phoneCd: '+351',
    euMember: Confirmation.YES,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'QA',
    krName: '카타르',
    name: 'Qatar',
    phoneCd: '+974',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'RO',
    krName: '루마니아',
    name: 'Romania',
    phoneCd: '+40',
    euMember: Confirmation.YES,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'RU',
    krName: '러시아',
    name: 'Russian Federation',
    phoneCd: '+7',
    euMember: Confirmation.NO,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'RW',
    krName: '르완다',
    name: 'Rwanda',
    phoneCd: '+250',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'WS',
    krName: '사모아',
    name: 'Samoa',
    phoneCd: '+685',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'SM',
    krName: '산마리노',
    name: 'San Marino',
    phoneCd: '+378',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'ST',
    krName: '상투메 프린시페',
    name: 'Sao Tome and Principe',
    phoneCd: '+239',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AFRICA
  },
  {
    code: 'SA',
    krName: '사우디아라비아',
    name: 'Saudi Arabia',
    phoneCd: '+966',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'SN',
    krName: '세네갈',
    name: 'Senegal',
    phoneCd: '+221',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'RS',
    krName: '세르비아',
    name: 'Serbia',
    phoneCd: '+381',
    euMember: Confirmation.NO,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'SC',
    krName: '세이셸',
    name: 'Seychelles',
    phoneCd: '+248',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'SL',
    krName: '시에라리온',
    name: 'Sierra Leone',
    phoneCd: '+232',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'SG',
    krName: '싱가포르',
    name: 'Singapore',
    phoneCd: '+65',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHEAST_ASIA
  },
  {
    code: 'SK',
    krName: '슬로바키아',
    name: 'Slovakia',
    phoneCd: '+421',
    euMember: Confirmation.YES,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'SI',
    krName: '슬로베니아',
    name: 'Slovenia',
    phoneCd: '+386',
    euMember: Confirmation.YES,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'SB',
    krName: '솔로몬 제도',
    name: 'Solomon Islands',
    phoneCd: '+677',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'TK',
    krName: '토켈라우',
    name: 'Tokelau',
    phoneCd: '+690',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'SO',
    krName: '소말리아',
    name: 'Somalia',
    phoneCd: '+252',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'ZA',
    krName: '남아프리카 공화국',
    name: 'South Africa',
    phoneCd: '+27',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_AFRICA
  },
  {
    code: 'SS',
    krName: '남수단',
    name: 'South Sudan',
    phoneCd: '+211',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'ES',
    krName: '스페인',
    name: 'Spain',
    phoneCd: '+34',
    euMember: Confirmation.YES,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'LK',
    krName: '스리랑카',
    name: 'Sri Lanka',
    phoneCd: '+94',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_ASIA
  },
  {
    code: 'SD',
    krName: '수단',
    name: 'Sudan',
    phoneCd: '+249',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTH_AFRICA
  },
  {
    code: 'SR',
    krName: '수리남',
    name: 'Suriname',
    phoneCd: '+597',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'SE',
    krName: '스웨덴',
    name: 'Sweden',
    phoneCd: '+46',
    euMember: Confirmation.YES,
    region: REGION_LIST.NORTHERN_EUROPE
  },
  {
    code: 'CH',
    krName: '스위스',
    name: 'Switzerland',
    phoneCd: '+41',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'SY',
    krName: '시리아',
    name: 'Syria',
    phoneCd: '+963',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'TW',
    krName: '대만',
    name: 'Taiwan, Province of China',
    phoneCd: '+886',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_ASIA
  },
  {
    code: 'TJ',
    krName: '타지키스탄',
    name: 'Tajikistan',
    phoneCd: '+992',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_ASIA
  },
  {
    code: 'TZ',
    krName: '탄자니아',
    name: 'Tanzania, United Republic of',
    phoneCd: '+255',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'TH',
    krName: '태국',
    name: 'Thailand',
    phoneCd: '+66',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHEAST_ASIA
  },
  {
    code: 'TL',
    krName: '동티모르',
    name: 'Timor-Leste',
    phoneCd: '+670',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHEAST_ASIA
  },
  {
    code: 'TG',
    krName: '토고',
    name: 'Togo',
    phoneCd: '+228',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_AFRICA
  },
  {
    code: 'TO',
    krName: '통가',
    name: 'Tonga',
    phoneCd: '+676',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'TT',
    krName: '트리니다드 토바고',
    name: 'Trinidad and Tobago',
    phoneCd: '+1-868',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'TC',
    krName: '터크스 케이커스 제도',
    name: 'Turks and Caicos Islands',
    phoneCd: '+1-649',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'VG',
    krName: '영국령 버진 아일랜드',
    name: 'British Virgin Islands',
    phoneCd: '+1-284',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'VI',
    krName: '미국령 버진 아일랜드',
    name: 'United States Virgin Islands',
    phoneCd: '+1-340',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'TN',
    krName: '튀니지',
    name: 'Tunisia',
    phoneCd: '+216',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTH_AFRICA
  },
  {
    code: 'EH',
    krName: '서사하라',
    name: 'Western Sahara',
    phoneCd: '+212',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTH_AFRICA
  },
  {
    code: 'TR',
    krName: '터키',
    name: 'Turkey',
    phoneCd: '+90',
    euMember: Confirmation.NO,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'TM',
    krName: '투르크메니스탄',
    name: 'Turkmenistan',
    phoneCd: '+993',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_ASIA
  },
  {
    code: 'TV',
    krName: '투발루',
    name: 'Tuvalu',
    phoneCd: '+688',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'UM',
    krName: '미국 군소 외딴 섬',
    name: 'United States Minor Outlying Islands',
    phoneCd: '+246',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'UG',
    krName: '우간다',
    name: 'Uganda',
    phoneCd: '+256',
    euMember: Confirmation.NO,
    region: REGION_LIST.EAST_AFRICA
  },
  {
    code: 'AX',
    krName: '올란드 제도',
    name: 'Åland Islands',
    phoneCd: '+358',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTHERN_EUROPE
  },
  {
    code: 'UA',
    krName: '우크라이나',
    name: 'Ukraine',
    phoneCd: '+380',
    euMember: Confirmation.NO,
    region: REGION_LIST.EASTERN_EUROPE
  },
  {
    code: 'AE',
    krName: '아랍에미리트',
    name: 'United Arab Emirates',
    phoneCd: '+971',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'GB',
    krName: '영국',
    name: 'United Kingdom of Great Britain and Northern Ireland',
    phoneCd: '+44',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'US',
    krName: '미국',
    name: 'United States of America',
    phoneCd: '+1',
    euMember: Confirmation.NO,
    region: REGION_LIST.NORTH_AMERICA
  },
  {
    code: 'UY',
    krName: '우루과이',
    name: 'Uruguay',
    phoneCd: '+598',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'UZ',
    krName: '우즈베키스탄',
    name: 'Uzbekistan',
    phoneCd: '+998',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_ASIA
  },
  {
    code: 'VU',
    krName: '바누아투',
    name: 'Vanuatu',
    phoneCd: '+678',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'WF',
    krName: '월리스 푸투나',
    name: 'Wallis and Futuna',
    phoneCd: '+681',
    euMember: Confirmation.NO,
    region: REGION_LIST.OCEANIA
  },
  {
    code: 'VA',
    krName: '바티칸 시국',
    name: 'Vatican',
    phoneCd: '+39-06',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_EUROPE
  },
  {
    code: 'VE',
    krName: '베네수엘라',
    name: 'Venezuela (Bolivarian Republic of)',
    phoneCd: '+58',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTH_AMERICA
  },
  {
    code: 'VN',
    krName: '베트남',
    name: 'Viet Nam',
    phoneCd: '+84',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHEAST_ASIA
  },
  {
    code: 'YE',
    krName: '예멘',
    name: 'Yemen',
    phoneCd: '+967',
    euMember: Confirmation.NO,
    region: REGION_LIST.WESTERN_ASIA
  },
  {
    code: 'ZM',
    krName: '잠비아',
    name: 'Zambia',
    phoneCd: '+260',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_AFRICA
  },
  {
    code: 'ZW',
    krName: '짐바브웨',
    name: 'Zimbabwe',
    phoneCd: '+263',
    euMember: Confirmation.NO,
    region: REGION_LIST.SOUTHERN_AFRICA
  },
  {
    code: 'AI',
    krName: '앵귈라',
    name: 'Anguilla',
    phoneCd: '+1',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  },
  {
    code: 'BQ',
    krName: '보네르',
    name: 'Bonaire',
    phoneCd: '+599',
    euMember: Confirmation.NO,
    region: REGION_LIST.CENTRAL_AMERICA
  }
];

export const EU_NATIONAL_LIST: string[] = COUNTRY_LIST.filter(
  ({ euMember }: CommonCountry) => euMember === Confirmation.YES
).map(({ code }: CommonCountry) => code);

export const DEFAULT_COUNTRY_CODE_FOR_SEARCH_COUNTRY = 'US';

export const COUNTRY_OPTIONS = COUNTRY_LIST.reduce((acc: FormOptionGroup[], cur: CommonCountry) => {
  const { code, phoneCd } = cur;

  acc.push({
    label: `studio.national_code.${code.toLowerCase()}`,
    value: code.toUpperCase(),
    extendedLabel: phoneCd
  });
  return acc;
}, []);

export const defaultCountryOption = COUNTRY_OPTIONS.find(
  (item: FormOption) => item.value === DEFAULT_COUNTRY_CODE_FOR_SEARCH_COUNTRY
);

export const CORRECT_REGION_ORDER = [
  REGION_LIST.NORTH_AMERICA,
  REGION_LIST.CENTRAL_AMERICA,
  REGION_LIST.SOUTH_AMERICA,
  REGION_LIST.WESTERN_EUROPE,
  REGION_LIST.EASTERN_EUROPE,
  REGION_LIST.NORTHERN_EUROPE,
  REGION_LIST.EAST_ASIA,
  REGION_LIST.SOUTHEAST_ASIA,
  REGION_LIST.WESTERN_ASIA,
  REGION_LIST.SOUTHERN_ASIA,
  REGION_LIST.CENTRAL_ASIA,
  REGION_LIST.OCEANIA,
  REGION_LIST.NORTH_AFRICA,
  REGION_LIST.WESTERN_AFRICA,
  REGION_LIST.CENTRAL_AFRICA,
  REGION_LIST.EAST_AFRICA,
  REGION_LIST.SOUTHERN_AFRICA,
  REGION_LIST.OTHER
];

export const CORRECT_REGION_ORDER_CODE = [
  'NA',
  'CA',
  'SA',
  'WE',
  'EE',
  'NE',
  'EA',
  'SEA',
  'WA',
  'SA',
  'CA',
  'OC',
  'NA',
  'WA',
  'CA',
  'EA',
  'SA',
  'OT'
];
